export const ELIGIBILITY_TABLE_ORDER = [
  "verified",
  "First",
  "Last",
  "DOB",
  "ID",
  "HCP",
  "IPA",
  "Web_HCP",
  "Web_IPA",
  "Web_IPA_Plan",
  "Web_Provider",
  "Web_copay",
  "Web_eligible_date",
  "orphaned",
  "saved",
];

export const COLORS = {
  yellow: "#EEC800",
  black: "#000",
  white: "#fff",
  lightzinc: "#d4d4d8",
};

export const INSURANCE_PROVIDERS = [
  "ANTHEM BLUE CROSS",
  "BLUE SHIELD",
  "UNITED HEALTHCARE",
  "CIGNA",
  "AETNA",
];

export const ENDPOINTS = [
  {
    name: "Home",
    icon: "fi-br-house-blank",
    endpoint: "/",
  },
  {
    name: "Insurance Verification",
    icon: "fi-br-check-circle",
    endpoint: "/insurance-verification",
  },
  {
    name: "Patient Referrals",
    icon: "fi-br-refer-arrow",
    endpoint: "/patient-referrals",
  },
  {
    name: "Billing",
    icon: "fi-bs-coins",
    endpoint: "/billing",
  },
  {
    name: "Support",
    icon: "fi-br-messages",
    endpoint: "/support",
  },
];
