const MicrosoftLogo = ({ size }) => {
  return (
    <svg
      height={size}
      viewBox="0 0 20 20"
      width={size}
      fit=""
      preserveAspectRatio="xMidYMid meet"
      focusable="false"
    >
      <g fill="none">
        <path d="M0 0h9.504v9.504H0z" fill="#F25022"></path>
        <path d="M10.496 0H20v9.504h-9.504z" fill="#7FBA00"></path>
        <path d="M0 10.496h9.504V20H0z" fill="#00A4EF"></path>
        <path d="M10.496 10.496H20V20h-9.504z" fill="#FFB900"></path>
      </g>
    </svg>
  );
};

export default MicrosoftLogo;
