import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Loading from "../../components/Loading";

const Home = ({ schema }) => {
  const [rows, setRows] = useState([]);
  const [newRow, setNewRow] = useState(null);
  const [editingRow, setEditingRow] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleAddRow = () => {
    if (!isAdding && !editingRow) {
      setIsAdding(true);
    }
  };

  const handleNewSave = () => {
    const existingRow = rows.find((row) => row.prefix === newRow.prefix);
    if (existingRow) {
      alert(`The portal with prefix "${newRow.prefix}" is already in use.`);
      return;
    }

    setIsAdding(false);
    createClinicData(newRow);
    setNewRow(null);
  };

  const handleNewCancel = () => {
    setNewRow(null);
    setIsAdding(false);
  };

  const handleEditRow = (id) => {
    if (editingRow) {
      return;
    }
    if (isAdding) {
      handleNewCancel();
      return;
    }
    const rowToEdit = rows.find((row) => row.id === id);
    setEditingRow(rowToEdit);
  };

  const handleSaveEditRow = (id) => {
    const existingRow = rows.find(
      (row) => row.prefix === editingRow.prefix && row.id !== id
    );

    if (existingRow) {
      alert(`The portal with prefix "${editingRow.prefix}" is already in use.`);
      return;
    }

    setRows(rows.map((row) => (row.id === id ? editingRow : row)));
    updateClinicData(id);
  };

  const handleCancelEditRow = () => {
    setEditingRow(null);
  };

  const handleDeleteRow = (id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this portal? This cannot be undone."
      )
    ) {
      if (isAdding) {
        handleNewCancel();
      }

      axios
        .delete(`${schema?.api_endpoint_root_url}clinic-portal-data`, {
          data: { id },
        })
        .then(() => {
          const updatedRows = rows.filter((row) => row.id !== id);
          setRows(updatedRows);
        })
        .catch((e) => {
          console.error("ERROR: ", e);
          alert("Error deleting portal.");
        });
    }
  };

  const handleEditInputChange = (field, value) => {
    setEditingRow({ ...editingRow, [field]: value });
  };

  const toggleShowPassword = (id) => {
    setRows(
      rows.map((row) =>
        row.id === id ? { ...row, showPassword: !row.showPassword } : row
      )
    );
  };

  // Server Calls

  const createClinicData = (data) => {
    axios
      .post(`${schema?.api_endpoint_root_url}clinic-portal-data`, {
        prefix: data?.prefix,
        username: data?.username,
        password: data?.password,
      })
      .then((res) => {
        setRows([...rows, res.data]);
        alert(`${data?.prefix} credentials created successfully.`);
      })
      .catch((e) => {
        console.error("ERROR: ", e);
        alert("Error updating credentials.");
      });
  };

  const updateClinicData = (id) => {
    const prefix = editingRow?.prefix;
    axios
      .post(`${schema?.api_endpoint_root_url}clinic-portal-data`, {
        id,
        prefix,
        username: editingRow?.username,
        password: editingRow?.password,
      })
      .then(() => {
        alert(`${prefix} credentials updated successfully.`);
        setEditingRow(null);
      })
      .catch((e) => {
        console.error("ERROR: ", e);
        alert("Error updating credentials.");
      });
  };

  const getClinicData = useCallback(() => {
    setLoading(true);
    setRows([]);
    axios
      .get(`${schema?.api_endpoint_root_url}clinic-portal-data`)
      .then((res) => {
        const data = res.data;
        setRows((prevRows) => [
          ...prevRows,
          ...data.map((item) => ({
            ...item,
            showPassword: false,
          })),
        ]);
      })
      .catch((e) => {
        console.error("ERROR: ", e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [schema?.api_endpoint_root_url]);

  useEffect(() => {
    getClinicData();
  }, [getClinicData]);

  return (
    <section className="flex h-screen bg-white flex-1 overflow-hidden flex flex-col">
      <h1 className="font-outfitSemiBold text-2xl font-bold p-4 py-6">
        {schema?.business_name}
      </h1>
      <div className="border-b border-zinc-200"></div>
      <div className="m-4 rounded-lg flex flex-col items-end border overflow-y-scroll">
        <div className="flex justify-between w-full p-4 items-center">
          <h3 className="text-xl">Manage Portals</h3>
          <button
            onClick={handleAddRow}
            className="gap-1 text-white bg-zinc-900 text-nowrap rounded-lg text-sm px-4 py-2 text-center inline-flex items-center transition-colors duration-200 ease-in-out hover:bg-zinc-700"
          >
            <i className="fi fi-br-plus w-3 h-3 mr-1 flex items-center justify-center"></i>
            Add Portal
          </button>
        </div>

        <div className="overflow-x-auto w-full">
          {loading ? (
            <div className="flex justify-center p-4">
              <Loading />
            </div>
          ) : (
            <table className="divide-y divide-zinc-200 w-full min-w-full bg-white">
              <thead className="text-xs text-zinc-700 uppercase bg-zinc-100">
                {rows.length > 0 ? (
                  <tr>
                    <th className="sticky top-0 bg-white  text-left p-3">
                      Portal
                    </th>
                    <th className="sticky top-0 bg-white  text-left p-3">
                      Username
                    </th>
                    <th className="sticky top-0 bg-white  text-left p-3">
                      Password
                    </th>
                    <th className="sticky top-0 bg-white  text-left p-3">
                      Actions
                    </th>
                  </tr>
                ) : null}
              </thead>
              <tbody className="divide-y divide-zinc-100 text-md">
                {rows.map((portal, index) => {
                  const isEditing = editingRow && editingRow?.id === portal.id;
                  return (
                    <tr
                      className={`${
                        index % 2 === 0 ? "bg-zinc-50" : "bg-white"
                      } ${isEditing ? "bg-blue-50" : null}`}
                      key={portal.id}
                    >
                      <td className="py-3 px-4 text-nowrap w-1/4">
                        {isEditing ? (
                          <input
                            value={editingRow?.prefix}
                            disabled
                            className="rounded-md p-1 w-full bg-transparent"
                            placeholder="Portal Name"
                          />
                        ) : (
                          <p>{portal?.prefix}</p>
                        )}
                      </td>
                      <td className="py-3 px-4 text-nowrap w-1/4">
                        {isEditing ? (
                          <input
                            value={editingRow?.username}
                            onChange={(e) =>
                              handleEditInputChange("username", e.target.value)
                            }
                            className="border border-zinc-300 rounded-md p-1 w-full"
                            placeholder="Username"
                          />
                        ) : (
                          <p>{portal?.username}</p>
                        )}
                      </td>
                      <td className="py-3 px-4 text-nowrap w-1/4">
                        {isEditing ? (
                          <input
                            value={editingRow?.password}
                            onChange={(e) =>
                              handleEditInputChange("password", e.target.value)
                            }
                            className="border border-zinc-300 rounded-md p-1 w-full"
                            placeholder="Password"
                          />
                        ) : (
                          <div className="flex items-center">
                            <p
                              className={`mr-2 ${
                                portal.showPassword ? null : "text-2xl"
                              }`}
                            >
                              {portal.showPassword
                                ? portal.password
                                : "••••••••"}
                            </p>
                          </div>
                        )}
                      </td>
                      <td className="py-3 px-4 text-nowrap flex">
                        {isEditing ? (
                          <>
                            <button
                              onClick={() => handleSaveEditRow(portal.id)}
                              className=" bg-green-200 text-green-900 hover:bg-green-300 transition p-1 rounded-md w-1/2 mr-2 flex justify-center items-center p-1"
                            >
                              <i className="fi fi-br-disk w-5 h-5 mr-1"></i>
                              <span>Save</span>
                            </button>
                            <button
                              onClick={handleCancelEditRow}
                              className=" bg-zinc-200 text-zinc-900 hover:bg-zinc-300 transition p-1 rounded-md w-1/2"
                            >
                              <span>Cancel</span>
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              onClick={() => toggleShowPassword(portal.id)}
                              className=" bg-blue-100 text-blue-900 hover:bg-blue-200 transition rounded-md mr-2 w-1/3 flex justify-center items-center p-1"
                            >
                              {portal.showPassword ? (
                                <i className="fi fi-ss-eye-crossed w-5 h-5 mr-1"></i>
                              ) : (
                                <i className="fi fi-ss-eye w-5 h-5 mr-1"></i>
                              )}
                              <span>
                                {portal.showPassword ? "Hide" : "View"}
                              </span>
                            </button>

                            <button
                              onClick={() => handleEditRow(portal.id)}
                              className=" bg-zinc-200 text-zinc-900 hover:bg-zinc-300 transition  rounded-md mr-2  w-1/3 flex justify-center items-center p-1"
                            >
                              <i className="fi fi-br-pencil w-5 h-5 mr-1"></i>
                              <span>Edit</span>
                            </button>

                            <button
                              onClick={() => handleDeleteRow(portal.id)}
                              className=" bg-red-100 text-red-900 hover:bg-red-200 transition rounded-md  w-1/3 flex items-center justify-center p-1"
                            >
                              <i className="fi fi-rr-trash w-5 h-5 mr-1"></i>
                              <span>Delete</span>
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
                {isAdding && (
                  <tr className="bg-blue-50">
                    <td className="py-3 px-4 text-nowrap">
                      <input
                        value={newRow?.prefix}
                        onChange={(e) =>
                          setNewRow({ ...newRow, prefix: e.target.value })
                        }
                        className="border border-zinc-300 rounded-md p-1 w-full"
                        placeholder="Portal Name"
                      />
                    </td>
                    <td className="py-3 px-4 text-nowrap">
                      <input
                        value={newRow?.username}
                        onChange={(e) =>
                          setNewRow({ ...newRow, username: e.target.value })
                        }
                        className="border border-zinc-300 rounded-md p-1 w-full"
                        placeholder="Username"
                      />
                    </td>
                    <td className="py-3 px-4 text-nowrap">
                      <input
                        value={newRow?.password}
                        onChange={(e) =>
                          setNewRow({ ...newRow, password: e.target.value })
                        }
                        className="border border-zinc-300 rounded-md p-1 w-full"
                        placeholder="Password"
                      />
                    </td>
                    <td className="py-3 px-4 text-nowrap flex justify-between">
                      <button
                        onClick={handleNewSave}
                        className="bg-green-200 text-green-900 hover:bg-green-300 transition p-1 rounded-md w-1/2 mr-2 flex justify-center items-center p-1"
                      >
                        <i className="fi fi-br-disk w-5 h-5 mr-1"></i>
                        <span>Save</span>
                      </button>
                      <button
                        onClick={handleNewCancel}
                        className="border bg-white text-zinc-900 hover:bg-zinc-200 transition p-1 rounded-md w-1/2"
                      >
                        Cancel
                      </button>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </section>
  );
};

export default Home;
