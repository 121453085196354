import React from "react";

const PatientReferrals = () => {
  return (
    <section className="flex h-screen bg-white flex-1 h-full overflow-hidden flex flex-col ">
      <h1 className="font-outfitSemiBold text-2xl font-bold p-4 py-6">
        Patient Referrals
      </h1>
      <div className="border-b border-zinc-200"></div>
    </section>
  );
};

export default PatientReferrals;
