// authService.js
import { auth, db } from "../../database/firebase/firebase";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

export const createNewUserRecord = async (
  uid,
  email,
  isNewUser,
  isApproved
) => {
  await setDoc(doc(db, "users", uid), {
    isApproved,
    isNewUser,
    email,
    uid,
  });
};

export const updateUserRecord = async (uid, isNewUser) => {
  await updateDoc(doc(db, "users", uid), {
    isNewUser,
  });
};

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const res = await signInWithPopup(auth, provider);
  return res;
};

export const signOutUser = async () => {
  await auth.signOut();
};

export const getUserDocument = async (uid) => {
  const userDoc = await getDoc(doc(db, "users", uid));
  return userDoc.data();
};
