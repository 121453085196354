import React, { useEffect, useState, useContext } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import axios from "axios";

// Components
import NavigationBar from "./components/NavigationBar";

// Pages
import AuthenticationPage from "./pages/Authentication";
import Home from "./pages/Home";
import InsuranceVerification from "./pages/InsuranceVerification";
import PatientReferrals from "./pages/PatientReferrals";
import Billing from "./pages/Billing";
import Support from "./pages/Support";
import PendingPage from "./pages/Pending";
import { AuthContext } from "./auth/firebase/AuthContext";

const App = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const protectedRoutes = [
    { path: "/", element: <Home /> },
    { path: "/insurance-verification", element: <InsuranceVerification /> },
    { path: "/patient-referrals", element: <PatientReferrals /> },
    { path: "/billing", element: <Billing /> },
    { path: "/support", element: <Support /> },
  ];

  const [schema, setSchema] = useState(null); // Schema starts as null

  useEffect(() => {
    // Fetch and propagate schema
    const schemaPath = process.env.REACT_APP_CLIENT_SCHEMA; // Use correct env var with REACT_APP_
    axios
      .get(`${schemaPath}`)
      .then((response) => {
        setSchema(response.data);
      })
      .catch((error) => {
        console.error("Error fetching or parsing JSON:", error);
        setSchema({});
      });
  }, []);

  return (
    <div className="flex">
      {schema ? (
        user ? (
          user.isApproved ? (
            <>
              <NavigationBar path={location} schema={schema} />
              <div className="flex-grow">
                <Routes>
                  {protectedRoutes.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={React.cloneElement(route.element, {
                        schema: schema || {},
                      })}
                    />
                  ))}
                </Routes>
              </div>
            </>
          ) : (
            <Routes>
              <Route
                key={"PendingPage"}
                path={"/"}
                element={<PendingPage />}
                schema={schema}
              />
            </Routes>
          )
        ) : (
          <Routes>
            <Route path="/" element={<AuthenticationPage schema={schema} />} />
          </Routes>
        )
      ) : null}
    </div>
  );
};

export default App;
