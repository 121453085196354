import { useState } from "react";

const Support = () => {
  const [requests, setRequests] = useState([]);
  const [newRequest, setNewRequest] = useState({
    description: "",
    type: "Feature Request",
  });
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [filterType, setFilterType] = useState("All");
  const [filterStatus, setFilterStatus] = useState("All");
  const [activeTab, setActiveTab] = useState("Submit Request");

  const handleInputChange = (e) => {
    setNewRequest({ ...newRequest, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (newRequest.description) {
      const currentDate = new Date().toLocaleDateString();
      setRequests([
        ...requests,
        { ...newRequest, status: "Pending", date: currentDate },
      ]);
      setNewRequest({ description: "", type: "Feature Request" });

      // Show alert after submitting the request
      window.alert("Your request has been submitted successfully!");
    }
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedRequests = [...requests].sort((a, b) => {
    if (sortConfig.key) {
      if (sortConfig.direction === "ascending") {
        return a[sortConfig.key].localeCompare(b[sortConfig.key]);
      } else {
        return b[sortConfig.key].localeCompare(a[sortConfig.key]);
      }
    }
    return 0;
  });

  const filteredRequests = sortedRequests.filter((request) => {
    const typeMatches = filterType === "All" || request.type === filterType;
    const statusMatches =
      filterStatus === "All" || request.status === filterStatus;
    return typeMatches && statusMatches;
  });

  return (
    <section className="flex h-screen bg-white flex-1 overflow-hidden flex flex-col">
      <h1 className="font-outfitSemiBold text-2xl font-bold p-4 py-6">
        Support
      </h1>
      <div className="border-b border-zinc-200"></div>

      <div className="flex px-4 py-2 space-x-4">
        <button
          className={`py-2 px-4 ${
            activeTab === "Submit Request"
              ? "border-b-2 border-blue-500 text-blue-500"
              : "text-zinc-500"
          }`}
          onClick={() => setActiveTab("Submit Request")}
        >
          Submit Request
        </button>
        <button
          className={`py-2 px-4 ${
            activeTab === "View Requests"
              ? "border-b-2 border-blue-500 text-blue-500"
              : "text-zinc-500"
          }`}
          onClick={() => setActiveTab("View Requests")}
        >
          View Requests
        </button>
      </div>

      <div className="flex flex-col flex-1">
        {activeTab === "Submit Request" && (
          <form onSubmit={handleFormSubmit} className="space-y-4 p-4">
            <p>
              Submit a request below or text us at{" "}
              <span className="px-2 py-1 bg-yellow-100 rounded-md text-yellow-900">
                (949) 419-7104
              </span>
              .
            </p>
            <select
              name="type"
              value={newRequest.type}
              onChange={handleInputChange}
              className="border p-1 w-full rounded"
            >
              <option value="Feature Request">Feature Request</option>
              <option value="Bug Report">Bug Report</option>
            </select>
            <textarea
              name="description"
              placeholder="Description"
              value={newRequest.description}
              onChange={handleInputChange}
              className="border p-1 w-full rounded max-h-48	h-48"
            />
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Submit Request
            </button>
          </form>
        )}

        {activeTab === "View Requests" && (
          <>
            <div className="flex space-x-4 p-4">
              <select
                value={filterType}
                onChange={(e) => setFilterType(e.target.value)}
                className="border p-1 rounded"
              >
                <option value="All">All Types</option>
                <option value="Feature Request">Feature Request</option>
                <option value="Bug Report">Bug Report</option>
              </select>
              <select
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
                className="border p-1 rounded"
              >
                <option value="All">All Statuses</option>
                <option value="Pending">Pending</option>
                <option value="Completed">Completed</option>
              </select>
            </div>

            <table className="min-w-full bg-white divide-y divide-zinc-200">
              <thead className="text-xs text-zinc-700 uppercase bg-zinc-100 border-b">
                <tr>
                  <th
                    className="sticky top-0 bg-white py-2 px-4 text-left cursor-pointer"
                    onClick={() => handleSort("description")}
                  >
                    Description
                  </th>
                  <th
                    className="sticky top-0 bg-white py-2 px-4 text-left cursor-pointer"
                    onClick={() => handleSort("date")}
                  >
                    Date
                  </th>
                  <th
                    className="sticky top-0 bg-white py-2 px-4 text-left cursor-pointer"
                    onClick={() => handleSort("status")}
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-zinc-100 text-md">
                {filteredRequests.map((request, index) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-white" : "bg-zinc-100"
                    } border-b`}
                  >
                    <td className="py-3 px-4 text-zinc-600">
                      {request.description}
                    </td>
                    <td className="py-3 px-4 text-sm text-zinc-500">
                      {request.date}
                    </td>
                    <td className="py-3 px-4">
                      <span
                        className={`inline-block px-2 rounded-md ${
                          request.status === "Pending"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-green-100 text-green-800"
                        }`}
                      >
                        {request.status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </section>
  );
};

export default Support;
