import React, { useContext, useEffect, useState } from "react";
import LogoIcon from "../Icons/LogoIcon";
import { COLORS, ENDPOINTS } from "../../constants";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../auth/firebase/AuthContext";

const NavigationBar = ({ path, schema }) => {
  const { signOut } = useContext(AuthContext);
  const [expanded, setExpanded] = useState(() => {
    // Load the expanded state from localStorage or default to false
    return JSON.parse(localStorage.getItem("navExpanded")) || false;
  });
  const [selected, setSelected] = useState(null);
  const [showProfileMenu, setShowProfileMenu] = useState(false); // State for showing/hiding profile menu

  const navigate = useNavigate();

  const toggleExpanded = () => {
    const newExpandedState = !expanded;
    setExpanded(newExpandedState);
    // Save the new state in localStorage
    localStorage.setItem("navExpanded", JSON.stringify(newExpandedState));
  };

  const handleSelect = (index) => {
    navigate(ENDPOINTS[index].endpoint);
    setSelected(index);
  };

  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu); // Toggle profile menu visibility
  };

  useEffect(() => {
    const currentIndex = ENDPOINTS.findIndex(
      (link) => link.endpoint === path.pathname
    );
    if (currentIndex !== -1) {
      setSelected(currentIndex);
    }
  }, [path]);

  return (
    <div
      className={`flex bg-zinc-100 flex-col justify-between items-center h-screen text-white border-r border-zinc-200 px-3 py-4 ${
        expanded ? "w-60" : ""
      }`}
    >
      <div className="flex flex-col w-full">
        <div
          className={`flex ${
            expanded ? "justify-between" : "justify-center"
          }  w-full mb-10`}
        >
          {expanded ? (
            <div className="p-1.5 rounded-md">
              <LogoIcon size={18} color={COLORS.yellow} />
            </div>
          ) : null}
          <i
            onClick={toggleExpanded}
            className={`fi fi-br-angle-double-small-${
              expanded ? "left" : "right"
            } text-lg p-1.5 flex items-center hover:bg-zinc-950/5 hover:text-zinc-600 rounded-md text-zinc-500`}
          ></i>
        </div>
        <ul className="flex flex-col items-start w-full gap-2">
          {ENDPOINTS.map((link, index) => (
            <li
              key={index}
              className={`flex items-center w-full cursor-pointer justify-center rounded-md  ${
                selected === index
                  ? `text-yellow-500 hover:bg-yellow-600/5`
                  : "hover:bg-zinc-950/5 hover:text-zinc-900 text-zinc-600"
              }`}
              onClick={() => handleSelect(index)}
            >
              <div
                className={`w-full flex justify-between p-1.5 items-center gap-2`}
              >
                <div className="flex items-center justify-center">
                  <i
                    className={`fi ${link.icon} text-lg w-6 h-6 flex items-center justify-center`}
                  ></i>
                </div>
                {expanded && (
                  <p className={`whitespace-nowrap w-full text-md`}>
                    {link.name}
                  </p>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className={`flex flex-col items-start gap-2 w-full`}>
        <div
          onClick={toggleProfileMenu}
          className={`flex items-center w-full cursor-pointer justify-center rounded-md  hover:bg-zinc-950/5 hover:text-zinc-900 text-zinc-600 w-60  ${
            showProfileMenu ? "bg-zinc-950/5 text-zinc-600" : ""
          }`}
        >
          <div className="w-full flex justify-between p-1.5 items-center gap-2">
            <i
              className={`fi fi-br-user text-lg w-6 h-6 flex items-center justify-center`}
            ></i>
            {expanded && (
              <p className={`whitespace-nowrap w-full text-md`}>
                {schema?.business_name}
              </p>
            )}
          </div>
        </div>
        {showProfileMenu && (
          <div
            className={`absolute bottom-16 left-3 flex flex-col bg-white shadow-lg border rounded-lg p-1.5`}
          >
            <button
              onClick={signOut}
              className="hover:text-red-700 px-5 py-2 text-sm text-red-500 whitespace-nowrap flex items-center space-x-2 "
            >
              <i className="fi fi-br-sign-out-alt flex justify-center items-center"></i>
              <p>Log Out</p>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavigationBar;
