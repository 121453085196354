import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

const Filter = ({ filters }) => {
  const handleDateChange = (date, index) => {
    const newDate = new Date(date);
    filters[index].actionHandler(newDate);
  };

  const renderFilters = () => {
    return filters.map((filter, index) => {
      if (filter.name === "searchQuery") {
        return (
          <div
            key={filter.name}
            className="relative w-full flex items-center border-b border-zinc-200"
          >
            <input
              id="search-table"
              type="text"
              className="text-zinc-900 text-sm w-full p-2 text-start block"
              placeholder="Search"
              value={filter.value}
              onChange={filter.actionHandler}
              required=""
            />
            <button
              className="mr-2"
              onClick={() => {
                document.getElementById("search-table").focus();
              }}
            >
              <i className="fi fi-br-search text-lg p-1.5 flex items-center justify-center rounded-md hover:bg-zinc-100 transition-colors duration-300"></i>
            </button>
          </div>
        );
      }
      if (filter.name === "selectedDate") {
        return (
          <div
            key={filter.name}
            className="relative flex items-center border-b border-zinc-200"
          >
            <button
              onClick={() => {
                const decrementedDate = new Date(filter.value);
                decrementedDate.setDate(decrementedDate.getDate() - 1);
                handleDateChange(decrementedDate, index);
              }}
            >
              <i
                className="fi fi-br-angle-small-left text-2xl p-0.5 flex items-center justify-center rounded-md hover:bg-zinc-100 transition-colors duration-300"
                alt={`Uicons by <a href="https://www.flaticon.com/uicons">Flaticon</a>`}
              ></i>
            </button>
            <DatePicker
              selected={filter.value}
              onChange={(date) => handleDateChange(date, index)}
              className="text-zinc-900 text-sm p-2 text-center block"
            />
            <button
              onClick={() => {
                const incrementedDate = new Date(filter.value);
                incrementedDate.setDate(incrementedDate.getDate() + 1);
                handleDateChange(incrementedDate, index);
              }}
            >
              <i
                className="fi fi-br-angle-small-right text-2xl p-0.5 flex items-center justify-center rounded-md hover:bg-zinc-100 transition-colors duration-300"
                alt={`Uicons by <a href="https://www.flaticon.com/uicons">Flaticon</a>`}
              ></i>
            </button>
          </div>
        );
      }
      return null;
    });
  };

  return (
    <div className="flex items-center w-full mr-4 md:w-2/3 xl:w-1/3 lg:mr-0 space-x-4">
      {renderFilters()}
    </div>
  );
};

export default Filter;
