import React from "react";

const ActionsMenu = ({ isActive, actionHandler }) => {
  const handleButtonClick = () => {
    if (isActive) {
      actionHandler();
    }
  };

  return (
    <div className="flex items-center justify-center relative">
      <button
        className={`gap-1 text-white text-nowrap rounded-lg text-sm px-4 py-2 text-center inline-flex items-center transition-colors duration-200 ease-in-out ${
          isActive
            ? "bg-zinc-900 hover:bg-zinc-800"
            : "bg-zinc-900/20 cursor-not-allowed"
        }`}
        onClick={handleButtonClick}
        data-tooltip-target="tooltip-light"
      >
        <i className="fi fi-sr-sparkles w-4 h-4 flex items-center justify-center"></i>
        Verify
      </button>
    </div>
  );
};

export default ActionsMenu;
