import React, { useContext } from "react";
import { AuthContext } from "../../auth/firebase/AuthContext";

const PendingPage = ({ schema }) => {
  const { signOut } = useContext(AuthContext);
  return (
    <div className="flex flex-col w-full bg-zinc-100">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen w-full gap-y-8">
        <div className="w-full bg-white rounded-xl md:mt-0 sm:max-w-md xl:p-0 border-2">
          <div className="p-6 space-y-4">
            <img
              src={schema?.business_logo_url}
              className="w-36 flex justify-self-center"
              alt={schema?.business_name}
            />
            <h1 className="text-xl font-bold leading-tight tracking-tight text-zinc-900 md:text-2xl">
              Pending Account Approval
            </h1>
            <p className="text-md text-zinc-600">
              Please contact your administrator to activate your account.
            </p>
            <button
              onClick={signOut}
              className="w-full text-white bg-zinc-900 hover:bg-zinc-700 focus:ring-4 focus:outline-none focus:ring-zinc-300 font-medium rounded-lg text-md px-5 py-2.5 text-center"
            >
              Return to Login
            </button>
            <button
              onClick={() => window.open("mailto:warren@runtalos.com")}
              className="w-full text-zinc-900 border bg-white hover:bg-zinc-100 focus:ring-4 focus:outline-none focus:ring-zinc-200 font-medium rounded-lg text-md px-5 py-2.5 text-center"
            >
              Contact Support
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingPage;
