// AuthContext.js
import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../database/firebase/firebase";
import {
  getAdditionalUserInfo,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  createNewUserRecord,
  updateUserRecord,
  signInWithGoogle,
  signOutUser,
  getUserDocument,
} from "./authService";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isNewUser, setIsNewUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const authenticateWithGoogle = async () => {
    try {
      const res = await signInWithGoogle();
      const uid = res.user.uid;
      const email = res.user.email;
      const details = getAdditionalUserInfo(res);

      if (details.isNewUser) {
        setIsNewUser(true);
        await createNewUserRecord(uid, email, true, false);
      } else {
        setIsNewUser(false);
        await updateUserRecord(uid, false);
      }
    } catch (error) {
      console.error("Error during Google Authentication:", error);
    }
  };

  const authenticateWithEmailPassword = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      switch (error.code) {
        case "auth/user-not-found":
          throw new Error("User not found. Please check your email.");
        case "auth/wrong-password":
          throw new Error("Incorrect password. Please try again.");
        case "auth/invalid-email":
          throw new Error("Invalid email format.");
        default:
          throw new Error("Login failed. Please try again later.");
      }
    }
  };

  const signOut = async () => {
    setUser(null);
    try {
      await signOutUser();
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (authUser) => {
      setLoading(true);
      if (authUser) {
        try {
          const userData = await getUserDocument(authUser.uid);
          console.info("Logged in user: ", authUser);
          setUser({ ...authUser, ...userData });
        } catch (error) {
          console.error("Error fetching user data:", error);
          setUser(null); // Set to null only in case of failure
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        isNewUser,
        authenticateWithGoogle,
        authenticateWithEmailPassword,
        signOut,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};
