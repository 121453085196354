import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";

import { ELIGIBILITY_TABLE_ORDER } from "../../constants";

// components
import Loading from "../../components/Loading";
import ActionsMenu from "../../components/ActionsMenu";
import Filter from "../../components/Filter";

const InsuranceVerification = () => {
  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedDate, setSelectedDate] = useState(Date.now());
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [rowCheckboxes, setRowCheckboxes] = useState({});
  const [dataError, setDataError] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  /**
   * INSURANCE ELIGIBILITY VERIFICATION API calls
   * 
   * Idea 1:
   * - getPatientsForDate(date)
   * - getEligibility(patient)
   * 
   * Idea 2:
   * - getEligibilityData(date)
   */

  const getEligibilityData = useCallback(() => {
    const formattedDate = new Date(selectedDate)
      .toLocaleDateString("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "");

    axios
      .get("http://127.0.0.1:5000/data", {
        params: { date: formattedDate },
      })
      .then((res) => {
        const data = res.data;
        setTableData(data);
        setFilteredData(data);
        setLoadingData(false);
      })
      .catch(() => {
        setDataError(true);
        setTableData([]);
        setFilteredData([]);
        setLoadingData(true);
        setSelectAllChecked(false);
        setRowCheckboxes({});
        setSortConfig({
          key: null,
          direction: "ascending",
        });
      });
  }, [selectedDate]);

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    const filtered = tableData?.filter(
      (item) =>
        rowCheckboxes[item.ID] ||
        ELIGIBILITY_TABLE_ORDER.some((key) =>
          item[key]?.toLowerCase().includes(query)
        )
    );
    if (selectAllChecked && filteredData.length !== tableData.length) {
      setSelectAllChecked(false);
    }
    setSearchQuery(query);
    setFilteredData(filtered);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectAllChecked(false);
    setRowCheckboxes({});
    setSearchQuery("");
    setDataError(false);
    setLoadingData(true);
  };

  const handleCheckboxChange = (item) => (e) => {
    const checked = e.target.checked;
    setRowCheckboxes((prevCheckboxes) => {
      const newCheckboxes = { ...prevCheckboxes, [item.ID]: checked };
      if (selectAllChecked && !checked) {
        setSelectAllChecked(false);
      }
      return newCheckboxes;
    });
  };

  const handleCheckboxAllChange = (e) => {
    const checked = e.target.checked;
    const newCheckboxes = {};
    filteredData.forEach((item) => {
      newCheckboxes[item.ID] = checked;
    });
    setRowCheckboxes(newCheckboxes);
    setSelectAllChecked(checked);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = useMemo(() => {
    if (sortConfig.key !== null) {
      return [...filteredData].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredData;
  }, [filteredData, sortConfig]);

  useEffect(() => {
    if (!dataError && loadingData) {
      getEligibilityData();
    }
  }, [loadingData, rowCheckboxes, selectedDate, dataError, getEligibilityData]);

  return (
    <section className="flex h-screen bg-white flex-1 h-full overflow-hidden flex flex-col">
      <h1 className="font-outfitSemiBold text-2xl font-bold p-4 py-6">
        Insurance Verification
      </h1>
      <div className="border-b border-zinc-200"></div>
      <div className="flex items-center justify-between p-4 ">
        <Filter
          filters={[
            {
              name: "searchQuery",
              value: searchQuery,
              actionHandler: handleSearchChange,
            },
            {
              name: "selectedDate",
              value: selectedDate,
              actionHandler: handleDateChange,
            },
          ]}
        />
        <ActionsMenu
          isActive={Object.values(rowCheckboxes).some((value) => value)}
          actionHandler={() =>
            console.log("TODO: HANDLE VERIFICATION FOR CHECKED ROWS")
          }
        />
      </div>
      <div className="overflow-auto border-b border-zinc-200 flex flex-1">
        {dataError ? (
          <div className="flex-1 flex flex-col justify-center items-center text-lg text-zinc-400">
            <i className="fi fi-br-cloud-question text-4xl"></i>
            No records for{" "}
            {new Date(selectedDate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </div>
        ) : loadingData && !dataError ? (
          <div className="flex-1 flex justify-center items-center">
            <Loading />
          </div>
        ) : (
          <table className="divide-y divide-zinc-200 w-full bg-blue h-min">
            <thead className="text-xs text-zinc-700 uppercase bg-zinc-100 ">
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 bg-white p-3 text-center"
                >
                  <input
                    id="checkbox-all"
                    type="checkbox"
                    className="w-4 h-4 bg-zinc-100 border-zinc-300 rounded text-primary-600"
                    checked={selectAllChecked || false}
                    onChange={handleCheckboxAllChange}
                  />
                </th>

                {ELIGIBILITY_TABLE_ORDER.map((key) => (
                  <th
                    scope="col"
                    className="sticky top-0 bg-white p-3 cursor-pointer "
                    key={key}
                    onClick={() => handleSort(key)}
                  >
                    <div className="flex items-center justify-center">
                      {<p className="mr-1">{key}</p>}
                      {sortConfig.key === key ? (
                        sortConfig.direction === "ascending" ? (
                          <i className="fi fi-br-caret-down w-4 h-4 text-lg flex items-center justify-center"></i>
                        ) : (
                          <i className="fi fi-br-caret-up w-4 h-4 text-lg flex items-center justify-center"></i>
                        )
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-zinc-100 text-md">
              {sortedData.map((item, index) => (
                <tr
                  key={index}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-zinc-100"}`}
                >
                  <td className="p-3 text-nowrap text-center">
                    <input
                      id={`checkbox-row-${index}`}
                      type="checkbox"
                      className="w-4 h-4 bg-zinc-100 border-zinc-300 rounded text-primary-600"
                      checked={rowCheckboxes[item.ID] || false}
                      onChange={handleCheckboxChange(item)}
                    />
                  </td>
                  {ELIGIBILITY_TABLE_ORDER.map((key) => (
                    <td className="p-3 text-nowrap" key={key}>
                      {key === "verified" ? (
                        <span
                          className={`inline-block w-full rounded-md text-center ${
                            item[key] === "Yes"
                              ? "bg-green-200 text-green-900"
                              : "bg-red-200 text-red-900"
                          }`}
                        >
                          {item[key]}
                        </span>
                      ) : (
                        item[key] ?? "-"
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </section>
  );
};

export default InsuranceVerification;
